<template>
  <div>
    <CrudRead
      v-bind="this.$store.state.stone_model[_modelName]"
      :urlModelName="_urlModelName"
    ></CrudRead>
  </div>
</template>

<script>
export default {
  computed: {
    _modelName() {
      return this.$route.meta.modelName;
    },
    _label() {
      return this.$route.meta.label ? this.$route.meta.label : null;
    },
    _urlModelName() {
      return this.$route.meta.urlModelName
        ? this.$route.meta.urlModelName
        : this._modelName;
    },
  },
  mounted() {},
};
</script>